/** @jsx jsx */
import Img from "gatsby-image"
import { jsx } from "theme-ui"
import { useState } from "react"
import { Lightbox } from "./light-box"
import React from "react"

const PostCard = ({ data }) => {
  console.log(data.frontmatter.featuredImage)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <article
      className="post-card"
      sx={{
        bg: "cardBg",
      }}
    >
      {data.frontmatter.featuredImage ? (
        <>
          <a href="#" role="button" onClick={() => setIsOpen(true)}>
            <Img
              fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={data.frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          </a>
          <Lightbox
            data={data}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          ></Lightbox>
        </>
      ) : (
        ""
      )}
      <div className="post-content">
        <h2 className="title">
          <a
            href="#"
            role="button"
            onClick={() => setIsOpen(true)}
            sx={{
              variant: "links.postLink",
            }}
          >
            {data.frontmatter.title}
          </a>
        </h2>
        <p
          className="meta"
          sx={{
            color: "muted",
          }}
        >
          <time>{data.frontmatter.date}</time>
        </p>
      </div>
    </article>
  )
}

export default PostCard
