import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";
import Img from "gatsby-image";
import React from 'react';
import { RiCloseLine } from "react-icons/ri";


export const Lightbox = ({ data, isOpen, onClose }) => {
  return (
    isOpen ?
      <Dialog style={{ background: "transparent" }} isOpen={isOpen} onDismiss={() => onClose(false)} aria-label={data.frontmatter.title}>
        <div className="close-button-container">
          <button className="close-button" onClick={() => onClose()}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden><RiCloseLine /></span>
          </button>
        </div>
        <Img
          fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="75% 75%"
          alt={data.frontmatter.title}
          className="featured-image"
        />
      </Dialog>
      : ""
  )
}